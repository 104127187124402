<template>
  <div>
    <!-- 第一个卡片 -->
    <el-card v-if="digital == 1">
      <div class="top">
        <ol>
          <li>
            | 一般隐患统计<br /><br /><span>{{ card_top.yb_hidd }}</span>
          </li>
          <li>
            | 重大隐患统计<br /><br /><span>{{ card_top.zd_hidd }}</span>
          </li>
          <li>
            | 隐患总计<br /><br /><span>{{ card_top.zj_hidd }}</span>
          </li>
        </ol>
      </div>
      <el-card>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="项目名称:">
            <el-input
              placeholder="请输入项目名称"
              v-model="pro"
              size="small"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              size="small"
              style="margin-left: 15px"
              @click="query"
              >查询</el-button
            >
            <el-button type="warning" size="small" @click="reset"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
      <!-- //表单 -->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#0000CD', color: '#ffffff' }"
      >
        <el-table-column prop="name" label="项目名称"> </el-table-column>
        <el-table-column prop="corporations" label="所属公司">
        </el-table-column>
        <el-table-column prop="site" label="所属区域"> </el-table-column>
        <el-table-column prop="hidd_yb" label="一般隐患"> </el-table-column>
        <el-table-column prop="hidd_zd" label="重大隐患"> </el-table-column>
        <el-table-column prop="hidd_zh" label="隐患总计"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="chakan(scope.row)"
              >查看详情</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 20]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-else-if="digital == 2">
      <el-button type="primary" round @click="superior" class="butt" size="mini"
        >返回上一级</el-button
      >
      <div class="top">
        <ol>
          <li>
            | 一般隐患统计<br /><br /><span>{{ tqableData2_top.hidd_yb }}</span>
          </li>
          <li>
            | 重大隐患统计<br /><br /><span>{{ tqableData2_top.hidd_zd }}</span>
          </li>
          <li>
            | 隐患总计<br /><br /><span>{{ tqableData2_top.hidd_zg }}</span>
          </li>
        </ol>
      </div>
      <el-card>
        <div class="con-size">
          <p>
            项目名称:<span style="font-size: 14px">{{ aaa1.name }}</span>
          </p>
          <p>
            所属公司:<span style="font-size: 14px">{{
              aaa1.corporations
            }}</span>
          </p>
          <p>
            所在位置:<span style="font-size: 14px">{{ aaa1.site }}</span>
          </p>
        </div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="隐患级别:">
            <el-select v-model="riskgrade" placeholder="全部" size="small">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="治理状态:">
            <el-select v-model="audit_type" placeholder="全部" size="small">
              <el-option
                v-for="item in options1"
                :key="item.value1"
                :label="item.label1"
                :value="item.value1"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              style="margin-left: 15px"
              @click="queryday2"
              >查询</el-button
            >
            <el-button type="warning" size="small" @click="resetday2"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <!-- //表单 -->
        <el-table
          :data="tableData2"
          stripe
          style="width: 100%"
          :header-cell-style="{ background: '#0000CD', color: '#ffffff' }"
        >
          <el-table-column prop="id" label="隐患编号"> </el-table-column>
          <el-table-column prop="riskgrade" label="隐患级别"> </el-table-column>
          <el-table-column prop="tasktype" label="检查类型"> </el-table-column>
          <el-table-column prop="dutyname" label="检查负责人">
          </el-table-column>
          <el-table-column prop="refortime" label="检查时间"> </el-table-column>
          <el-table-column prop="reformname" label="整改负责人">
          </el-table-column>
          <el-table-column prop="audit_type" label="治理状态">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                @click="ck(scope.row)"
                >查看详情</el-link
              >
                <el-link
                type="primary"
                style="padding-left: 20px;"
                :underline="false"
                @click="del(scope.row)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- //分页功能卡片二 -->
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="page2"
          :page-sizes="[5, 10, 20]"
          :page-size="pagesize2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total2"
        >
        </el-pagination>
      </el-card>
    </el-card>
    <!-- 第三个卡片 -->
    <el-card v-else-if="digital == 3">
      <el-button
        type="primary"
        round
        class="butt"
        size="mini"
        @click="ReturnThree"
        >返回上一级</el-button
      >
      <el-card>
        
        <el-button type="text"  v-print="'#data1'">打印下发通知单</el-button>
        <div class="day3">
          <div>
          <el-form ref="form" label-width="80px" id='data1'>
            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="项目名称:">
                <el-input style="margin-left: 30px" v-model="aaa1.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="所属公司:">
                <el-input style="margin-left: 30px" v-model="aaa1.corporations"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="所属区域:">
                <el-input style="margin-left: 30px" v-model="aaa1.site"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="隐患编号:">
                <el-input style="margin-left: 30px" v-model="aaa2.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="隐患级别:">
                <el-input style="margin-left: 30px" v-model="aaa2.tasktype"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="检查类型:">
                <el-input style="margin-left: 30px" v-model="aaa2.taskname"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="检查时间:">
                <el-input style="margin-left: 30px" v-model="aaa2.refortime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="受检单位:">
                <el-input style="margin-left: 30px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="检查人员:">
                <el-input style="margin-left: 30px"></el-input>
              </el-form-item>
            </el-col>
            
            
            <el-col :span="6" style="margin-left: 20px">
              <el-form-item label="当前状态:">
                <el-input style="margin-left: 30px" v-model="aaa2.audit_type"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="整改时间:">
                <el-input style="margin-left: 30px" v-model="aaa2.refortime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 100px">
              <el-form-item label="负责人:">
                <el-input style="margin-left: 30px" v-model="aaa2.reformname"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6" style="margin-left: 20px;margin-top:13px;">
              <el-form-item label="隐患内容:">
                <el-input
                  type="textarea"
                  style="width: 1350px; height: 109px; margin-left: 30px"
                  v-model="aaa2.standards.data3.inpval"
                ></el-input>
              </el-form-item>
              <el-form-item label="处理方式:">
                {{aaa2.riskgrade}}
              </el-form-item>
            </el-col>
          </el-form>

          </div>
        </div>
        
      </el-card>
      <div v-if="this.panduan == 1 ">
        <br>
        <br>
        隐患整改反馈单
        <br>
        <br>
        <br>
        
        <el-button type="text"  v-print="'#data2'">打印整改反馈单</el-button>
        <br>
        <table border="1px" cellspacing="0" style="width: 100%; border-color: black;" id='data2'>
          <tr>
            <td style="width: 180px; height: 50px;">工程名称</td>
            <td style="width: 280px;">{{this.data_fankui.pro_name}}</td>
            <td  style="width: 180px;">隐患整改通知书编号</td>
            <td  style="width: 280px;"> {{this.data_fankui.id}}</td>
          </tr>
          <tr>
            <td style="width: 180px; height: 50px;">整改单位</td>
            <td>{{this.data_fankui.pro_name}}</td>
            <td>整改负责人</td>
            <td> {{this.data_fankui.datas.data3.data_xftz.xftz_zgfzr}}</td>
          </tr>
          <tr>
            <td colspan="4" style="width: 180px; height: 170px;">
              <div>
                整改要求：
              </div>
              <div style="padding-left: 100px;">
                {{this.data_fankui.datas.data3.data_xftz.xftz_zgyq}}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" style="width: 180px; height: 280px;">
              <div style="padding-top: 20px;">
                整改措施及结果：
              </div>
              <div style="padding-left: 150px;">
                {{this.data_fankui.zg_text}}
              </div>
              <div style="padding-top: 120px;">
                相关图片：
              </div>
              <img :src="this.data_fankui.zg_img" alt="" style="height: 200px;padding-left: 150px;">
              <div style="padding-left: 1100px;">
                整改负责人（签字）：
              </div>
              
              <div style="padding-left: 1300px;float: left;">
                年
              </div>
              <div style="padding-left: 86px;float: left;">
                月
              </div>
              <div style="padding-left: 1500px;">
                日
              </div>
            </td>
          </tr>
          <tr>
            <td align="center">
              复查意见
            </td>
            <td colspan="3" style="width: 180px; height: 280px;">
              <div>复查结论：</div>
              <div    style="padding-left: 1000px;    padding-top: 150px;">
                复查人（签字）：
              </div>
              <div style="padding-left: 1100px;float: left;">
                年
              </div>
              <div style="padding-left: 86px;float: left;">
                月
              </div>
              <div style="padding-left: 1300px;">
                日
              </div>
            </td>
          </tr>
        </table>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  addhiddenGovernance,
  addhiddenGovernance2,
  show_fankui,
  hidddel,
} from "../../../request/hidden";
export default {
  name: "",
  props: [],
  data() {
    return {
      panduan:0,
      tableData: [],
      tableData2: [], //卡片二的存放数据的数组
      aaa1: {},
      aaa2: {},
      tqableData2_top: [],
      card_top: [], //页面头部卡片统计隐患
      digital: 1, //判断显示第几个卡片
      page: 1,
      pagesize: 10,
      page2: 1,
      pagesize2: 10,
      total: 0, //分页总条数
      total2: 0, //卡片二的分页
      pro: "", //项目名称查询
      name: "", //公司名称查询
      options: [
        {
          label: "低隐患",
          value: "低隐患",
        },
        {
          label: "一般隐患",
          value: "一般隐患",
        },
        {
          label: "较大隐患",
          value: "较大隐患",
        },
        {
          label: "重大隐患",
          value: "重大隐患",
        },
      ],
      options1: [
        {
          label1: "已提交",
          value1: "已提交",
        },
        {
          label1: "审核通过",
          value1: "审核通过",
        },
        {
          label1: "已通过",
          value1: "已通过",
        },
        {
          label1: "未通过",
          value1: "未通过",
        },
      ],
      id: "", //存放第一页点击的项目id
      riskgrade: "", //查询隐患
      audit_type: "", //查询治理
      data_fankui:{},
      pjtid:'',

    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.digital = 1;
      this.shuju();
    },
  },
  methods: {
    async del(e){
      console.log(e.id)
      var msg={
        id:e.id
      } 
      const res = await hidddel(msg);
      this.getHiddenInfo()
    },
    async shuju() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
        name: this.pro,
        page: this.page,
        size: this.pagesize,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project') 
        }else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project') 
        }
      }
      const res = await addhiddenGovernance(msg);
      // console.log(res,123)
      this.card_top = res.data;
      this.total = res.data.count;
      this.tableData = res.data.data;
      // console.log(this.tableData,456)
    },
    //分页
    handleSizeChange(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize = pagesize;
      this.shuju();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.shuju();
    },
    //查询
    query() {
    this.page = 1

      this.shuju();
    },
    //重置
    reset() {
      this.pro = "";
      this.name = "";
      this.shuju();
    },
    //点击查看详情跳转
    chakan(row) {
      this.aaa1.name = row.name;
      this.aaa1.corporations = row.corporations;
      this.aaa1.site = row.site;
      
      this.id = row.id;
      this.digital = 2;
      this.getHiddenInfo();
    },
    async getHiddenInfo() {
      let msg = {
        audit_type: this.audit_type,
        riskgrade: this.riskgrade,
        page: this.page2,
        size: this.pagesize2,
        project_id: this.id,
      };
      const res = await addhiddenGovernance2(msg);
      this.total2 = res.data.count;
      this.tqableData2_top = res.data;
      this.tableData2 = res.data.data;
      // console.log(res,123)
      // console.log(this.tableData2,456)
    },
    //卡片二的分页
    handleSizeChange2(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize2 = pagesize;
      this.getHiddenInfo();
    },
    handleCurrentChange2(page) {
      // console.log(`当前页: ${page}`);
      this.page2 = page;
      this.getHiddenInfo();
    },
    //day2查询
    queryday2() {
      // this.chakan(this.id);
      this.getHiddenInfo();
    },
    // day2重置
    resetday2() {
      this.riskgrade = "";
      this.audit_type = "";
      this.getHiddenInfo();
    },
    superior() {
      this.shuju();
      this.digital = 1; //返回上一级
    },

    async ck(row) {
      this.aaa2.id = row.id;
      this.aaa2.riskgrade = row.riskgrade;
      this.aaa2.tasktype = row.tasktype;
      this.aaa2.refortime = row.refortime;
      this.aaa2.audit_type=row.audit_type;
      this.aaa2.refortime=row.refortime;
      this.aaa2.reformname=row.reformname;
      this.aaa2.details = row.details
      this.aaa2.standards = row.standards
      this.aaa2.taskname = row.taskname
      this.digital = 3;
      this.data_fankui={}
      this.panduan=0
      var msg={
        id:row.id
      }
      const res = await show_fankui(msg);
        console.log(res)
        this.data_fankui=res.data.data[0]
        console.log(this.data_fankui.zg_texti)
        if (this.data_fankui.zg_text==undefined){
          
          this.panduan=0
        }else{
           this.panduan=1

        }
    },

    ReturnThree() {
      this.digital = 2; //返回上一级
    },
  },
  mounted() {
    this.shuju();
  },
  components: {},
  computed: {},
};
</script>
<style lang='less' scoped>
.top {
  width: 100%;
  height: 120px;
}
ol > li {
  list-style: none;
  width: 160px;
  height: 104px;
  background-color: red;
  float: left;
  margin-left: 10px;
  padding: 20px 20px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
ol > li > span {
  margin: 10px;
}
ol > li:nth-child(1) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk1.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}
ol > li:nth-child(2) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk3.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}
ol > li:nth-child(3) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-table {
  margin-top: 10px;
  border-radius: 5px;
}
.con-size {
  width: 100%;
  height: 50px;
}
.con-size p {
  width: 33.3%;
  height: 30px;
  float: left;
  line-height: 30px;
  border-bottom: 1px solid black;
}
.el-col {
  margin-bottom: 8px;
 }


 .day3 /deep/ .el-textarea{
    width: 1300px !important;
    height: 200px !important;
  }
  .day3 /deep/ .el-textarea__inner{
   min-height:200px !important;
  }
  .butt{
    margin-bottom: 10px;
  }

</style>
 